import * as Sentry from '@sentry/nextjs';
import cookies from '@services/cookies';
import axios from 'axios';

const { getCookie } = cookies();

type ArgsType = {
  variables?: {};
  queryName?: string;
  mutationName?: string;
  queryParams?: string;
  mutationParams?: string;
};

const Graphql = (tokenPassed: string | undefined = undefined) => {
  const tokenContext = typeof window !== 'undefined' ? 'client' : 'server';

  const token: string =
    tokenPassed === undefined ? getCookie(tokenContext, 'token') : tokenPassed;

  const gqlQuery = async (
    collectionName: string,
    collectionParams: string,
    collectionReturns: string,
    args?: ArgsType
  ): Promise<any> => {
    try {
      const headersList = {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Encoding': '*'
      };

      const gqlBody = {
        query: `query ${args?.queryName || ''}${args?.queryParams ? '(' : ''}${
          args?.queryParams ? args?.queryParams : ''
        }${args?.queryParams ? ')' : ''} {
            ${collectionName}${collectionParams !== '' ? '(' : ''}${
          collectionParams !== '' ? collectionParams : ''
        }${collectionParams !== '' ? ')' : ''} {
              ${collectionReturns}
            }
          }`,
        variables: args?.variables || {}
      };

      const bodyContent = JSON.stringify(gqlBody);

      const reqOptions = {
        url: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
        method: 'POST',
        headers: headersList,
        data: bodyContent
      };

      const response = await axios.request(reqOptions);

      return response.data;
    } catch (error: any) {
      const dataError = {
        message: error?.message,
        data: error?.response?.data?.errors
      };
      Sentry.captureMessage(
        `[Request Candidato] Erro ao realizar a requisição "Query": ${JSON.stringify(
          dataError
        )}`,
        'fatal'
      );
      return {
        error: dataError
      };
    }
  };

  const gqlMutation = async (
    collectionName: string,
    collectionParams: string,
    collectionReturns: string,
    args?: ArgsType
  ): Promise<any> => {
    try {
      const headersList = {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Encoding': '*'
      };

      const gqlBody = {
        query: `mutation ${args?.mutationName || ''}${
          args?.mutationParams ? '(' : ''
        }${args?.mutationParams ? args?.mutationParams : ''}${
          args?.mutationParams ? ')' : ''
        } {
            ${collectionName}${collectionParams !== '' ? '(' : ''}${
          collectionParams !== '' ? collectionParams : ''
        }${collectionParams !== '' ? ')' : ''} {
              ${collectionReturns}
            }
          }`,
        variables: args?.variables || {}
      };

      const bodyContent = JSON.stringify(gqlBody);

      const reqOptions = {
        url: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
        method: 'POST',
        headers: headersList,
        data: bodyContent
      };

      const response = await axios.request(reqOptions);

      return response.data;
    } catch (error: any) {
      const dataError = {
        message: error?.message,
        data: error?.response?.data?.errors
      };
      Sentry.captureMessage(
        `[Request Candidato] Erro ao realizar a requisição "Mutation": ${JSON.stringify(
          dataError
        )}`,
        'fatal'
      );
      return {
        error: dataError
      };
    }
  };

  return { gqlQuery, gqlMutation };
};

export default Graphql;
