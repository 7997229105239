import { CookieUserTType, CookieUserType } from '@localTypes/userType';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import cookies from '../cookies';
import Graphql from '../graphql';

function User() {
  const router = useRouter();
  const { setNewCookie, deleteCookie } = cookies();
  const [token, setNewToken] = useState();
  const { gqlQuery, gqlMutation } = Graphql(token);
  const time = 60 * 60 * 2;

  const login = useCallback(
    async (email: string, password: string) => {
      const collectionParams =
        'input : { identifier: $identifier, password: $password}';
      const collectionReturns = 'jwt user { id } ';
      const mutationParams = '$identifier: String!, $password: String!';
      const variables = { identifier: email, password };
      const args = {
        variables,
        mutationParams
      };

      try {
        const response = await gqlMutation(
          'login',
          collectionParams,
          collectionReturns,
          args
        );

        if (response.data) {
          const { data } = response;
          setNewCookie('server', 'token', data?.login?.jwt, time, '/');
          setNewToken(data?.login?.jwt);

          const newArgs = {
            variables: { id: data?.login?.user?.id },
            queryParams: '$id : ID!'
          };

          const userInfo = await gqlQuery(
            'user',
            'id: $id',
            `
            id
            email
            firstLogin
            blocked
            role { id name }
            recruiter {
              id
              name
              subname
              cnpj
              email
              youtube
              imageUrl
              created_at
              about
            }
            company { id name cnpj email imageUrl created_at}
            applicant { id name subname imageUrl created_at}
          `,
            newArgs
          );

          const userData = userInfo?.data?.user;
          const firstLogin = { status: userData?.firstLogin };

          const user: CookieUserType = {
            id: userData?.id,
            firstLogin: userData?.firstLogin,
            name: `${userData?.applicant?.name} ${userData?.applicant?.subname}`,
            subname: userData?.applicant?.subname,
            image: userData?.applicant?.imageUrl,
            email: userData?.email,
            createdAt: userData?.applicant?.created_at
          };
          const value = userData?.applicant?.id;

          if (value === undefined || value === null || value === 0) {
            throw new Error('Usuário não possui conta de candidato');
          }
          const userType: CookieUserTType = {
            type: 'applicant',
            typeId:
              // userData?.company?.id ||
              // userData?.recruiter?.id ||
              userData?.applicant?.id
          };

          setNewCookie('server', 'user', JSON.stringify(user), time, '/');
          setNewCookie(
            'server',
            'userType',
            JSON.stringify(userType),
            time,
            '/'
          );
          setNewCookie('server', 'firstLogin', firstLogin, time, '/');

          return { user, userType };
        }

        throw new Error('Usuário não encontrado');
      } catch (error) {
        return {};
      }
    },
    [gqlMutation, gqlQuery, setNewCookie, time]
  );

  const logout = useCallback(() => {
    deleteCookie('client', 'user');
    deleteCookie('client', 'userType');
    deleteCookie('client', 'token');
    sessionStorage?.clear();
    localStorage?.clear();
    router.push('/');
  }, [deleteCookie, router]);

  const register = useCallback(
    async (email: string, password: string) => {
      try {
        const url = `${process.env.NEXT_PUBLIC_API_URL}/auth/local/register`;
        const response = await axios.post(
          url,
          {
            username: email,
            email,
            password
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        const data = response?.data;

        if (data?.errors) {
          throw new Error('Erro ao criar o usuário. Tente novamente.');
        }

        const userInfo = {
          id: data?.user.id,
          email: data?.user.email
        };

        setNewCookie('server', 'token', data?.jwt, time);
        setNewCookie('server', 'registed', JSON.stringify(userInfo), time);
        setNewCookie('server', 'firstLogin', true, time);

        return data?.user;
      } catch (error) {
        return {};
      }
    },
    [setNewCookie, time]
  );

  return {
    login,
    logout,
    register
  };
}

export default User;
