import { Button } from '@chakra-ui/react';
import { BsHeadset } from 'react-icons/bs';

interface Props {
  color: string;
}

function IntercomButton({ color }: Props) {
  return (
    <Button
      id="open_intercom_trigger"
      size="sm"
      marginRight="10px"
      leftIcon={<BsHeadset />}
      bg="transparent"
      border="1px solid rgba(75, 75, 75, 0.2)"
      transition="0.5s ease"
      _hover={{
        bg: 'transparent',
        borderColor: color,
        color
      }}
    >
      Ajuda
    </Button>
  );
}

export default IntercomButton;
