import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import IntercomButton from '@components/Buttons/IntercomButton';
import { BsHeadset } from 'react-icons/bs';
import MySwal from '@services/sweetAlert';
import User from '@services/users';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type React from 'react';
import { useState } from 'react';

function CustomIntercomButton() {
  return (
    <Button
      variant="outline"
      bg="#FFFFFF"
      borderColor="#C6C6C6"
      borderWidth={1}
      borderRadius={8}
      p={8}
      mt={15}
      id="open_intercom_trigger"
      transition="0.5s ease"
      leftIcon={<BsHeadset />}
      _hover={{
        bg: '#c6c6c6',
        color: '#4b4b4b'
      }}
    >
      Fale com o suporte
    </Button>
  );
}

function Login() {
  const user = User();
  const { login } = user;

  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordShow, setPasswordShow] = useState(false);
  const handlePasswordIconClick = () => setPasswordShow(!passwordShow);

  const handleSubmit = async (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (email === '' || password === '') {
        setIsLoading(false);
        MySwal.fire({
          icon: 'error',
          title: 'Opa...',
          html: 'Preencha os campos de Email e Senha corretamente.'
        });
      } else {
        const registeredUser = await login(email, password);

        if (registeredUser.user && registeredUser.userType) {
          router.push('/dashboard');
        } else {
          setIsLoading(false);
          MySwal.fire({
            icon: 'error',
            title: 'E-mail ou senha incorretos!',
            html: 'Por favor verifique suas credenciais.'
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      MySwal.fire({
        icon: 'error',
        title: 'Algo deu errado...',
        html: (
          <>
            <div>
              Aconteceu um erro inesperado do lado do servidor. Tente novamente
              mais tarde.
            </div>
            <CustomIntercomButton />
          </>
        )
      });
    }
  };

  return (
    <>
      <Head>
        <title>Login | Candidato - Huntly</title>
      </Head>
      <Flex
        minH="100vh"
        align="center"
        justify="center"
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
          <Stack align="center">
            <Image src="/img/logo.png" width="8vw" alt="logo" mb="2rem" />
            <Heading fontSize="2xl">
              <Center>Que bom ver você aqui!</Center>
            </Heading>
          </Stack>
          <Box
            rounded="lg"
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            p={8}
          >
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Senha</FormLabel>
                  <InputGroup size="md">
                    <Input
                      type={passwordShow ? 'text' : 'password'}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="Hide/Show Password"
                        icon={passwordShow ? <ViewIcon /> : <ViewOffIcon />}
                        onClick={handlePasswordIconClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <Stack spacing={2}>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    loadingText="Entrando..."
                    bg="#F25252"
                    color="white"
                    _hover={{
                      bg: '#BF4141'
                    }}
                  >
                    Entrar
                  </Button>
                  <br />
                  <Stack spacing={0} alignItems="center">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mb="10px"
                    >
                      <IntercomButton color="#F25252" />
                      <Link href="/recuperar-senha">
                        <Text cursor="pointer">Esqueci minha senha</Text>
                      </Link>
                    </Stack>
                    <Text mt="10" textAlign={['left', 'center']}>
                      Ainda não tem uma conta?
                      <Link href="/criar-conta" color="#F25252">
                        {' Cadastre-se.'}
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}

export default Login;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  if (
    ctx.req?.cookies?.hh_cd_user &&
    ctx.req?.cookies?.hh_cd_userType &&
    ctx.req?.cookies?.hh_cd_token
  ) {
    const destination = '/dashboard';

    return {
      redirect: {
        permanent: false,
        destination
      }
    };
  }

  return {
    props: {}
  };
};
